import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
const root = document.createElement('div');
root.className = 'application';
document.body.appendChild(root);

function Container(props) {
  const styles = 
  {
    position: 'absolute',
    width: '100%',
    height: '100%',
  };
  return (<div style={styles}>
    {props.children}
  </div>);
}

function useKeys(callback) {
  useEffect(() => {
    const keydown = e => {
      e.preventDefault();
      e.stopPropagation();
      callback(e.key);
    };
    window.addEventListener('keydown', keydown);
    return () => {
      window.removeEventListener('keydown', keydown);
    };
  }, []);
}

function KeyDisplay() {
  const [key, setKey] = useState('');
  useKeys(setKey);

  const styles = 
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '300px',
    fontFamily: 'sans-serif',
    height: '100%',
  };
  return (<div style={styles}>
    {key}
  </div>);
}

ReactDOM.render(
  <Container>
    <KeyDisplay />
  </Container>,
  root
);
